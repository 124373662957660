export const IDTypeOptions = [
  { text: '身份证', value: 1 },
  { text: '港澳居民往来大陆同行证', value: 2 },
  { text: '台湾居民往来大陆同行证', value: 3 },
  { text: '外国护照', value: 4 },
]

export const pageOptions = [
  { title: '每页5行', value: 5 },
  { title: '每页10行', value: 10 },
  { title: '每页15行', value: 15 },
  { title: '每页20行', value: 20 },
]

export const subtitleUploadStatusWithoutFailOptions = [
  { text: '进行中', value: 0 },
  { text: '上传中', value: 3 },
  { text: '上传失败', value: 4 },
  { text: '上传完成', value: 5 },
]

export const authResultOptions = [
  { text: '未授权', value: 0 },
  { text: '确权成功', value: 1 },
  { text: '确权失败', value: 2 },
  { text: '跳过确权审核中', value: 3 },
]

export const realNameAuthResultOptions = [
  { text: '审核中', value: 0 },
  { text: '通过', value: 1 },
  { text: '拒绝', value: -1 },
]

export const authStatusOptions = [
  { text: '未认证', color: 'secondary', value: 0 },
  { text: '已认证', color: 'info', value: 1 },
  { text: '暂不认证', color: 'warning', value: 3 },
]

export const countryOptions = [
  { value: 'China', text: '中国' },
  { value: 'Angola', text: '安哥拉' },
  { value: 'Afghanistan', text: '阿富汗' },
  { value: 'Albania', text: '阿尔巴尼亚' },
  { value: 'Algeria', text: '阿尔及利亚' },
  { value: 'Andorra', text: '安道尔共和国' },
  { value: 'Anguilla', text: '安圭拉岛' },
  { value: 'Antigua and Barbuda', text: '安提瓜和巴布达' },
  { value: 'Argentina', text: '阿根廷' },
  { value: 'Armenia', text: '亚美尼亚' },
  { value: 'Ascension', text: '阿森松' },
  { value: 'Australia', text: '澳大利亚' },
  { value: 'Austria', text: '奥地利' },
  { value: 'Azerbaijan', text: '阿塞拜疆' },
  { value: 'Bahamas', text: '巴哈马' },
  { value: 'Bahrain', text: '巴林' },
  { value: 'Bangladesh', text: '孟加拉国' },
  { value: 'Barbados', text: '巴巴多斯' },
  { value: 'Belarus', text: '白俄罗斯' },
  { value: 'Belgium', text: '比利时' },
  { value: 'Belize', text: '伯利兹' },
  { value: 'Benin', text: '贝宁' },
  { value: 'Bermuda Is', text: '百慕大群岛' },
  { value: 'Bolivia', text: '玻利维亚' },
  { value: 'Botswana', text: '博茨瓦纳' },
  { value: 'Brazil', text: '巴西' },
  { value: 'Brunei', text: '文莱' },
  { value: 'Bulgaria', text: '保加利亚' },
  { value: 'Burkina Faso', text: '布基纳法索' },
  { value: 'Burma', text: '缅甸' },
  { value: 'Burundi', text: '布隆迪' },
  { value: 'Cameroon', text: '喀麦隆' },
  { value: 'Canada', text: '加拿大' },
  { value: 'Cayman Is', text: '开曼群岛' },
  { value: 'Central African Republic', text: '中非共和国' },
  { value: 'Chad', text: '乍得' },
  { value: 'Chile', text: '智利' },
  { value: 'China', text: '中国' },
  { value: 'Colombia', text: '哥伦比亚' },
  { value: 'Congo', text: '刚果' },
  { value: 'Cook Is', text: '库克群岛' },
  { value: 'Costa Rica', text: '哥斯达黎加' },
  { value: 'Cuba', text: '古巴' },
  { value: 'Cyprus', text: '塞浦路斯' },
  { value: 'Czech Republic', text: '捷克' },
  { value: 'Denmark', text: '丹麦' },
  { value: 'Djibouti', text: '吉布提' },
  { value: 'Dominica Rep', text: '多米尼加共和国' },
  { value: 'Ecuador', text: '厄瓜多尔' },
  { value: 'Egypt', text: '埃及' },
  { value: 'EI Salvador', text: '萨尔瓦多' },
  { value: 'Estonia', text: '爱沙尼亚' },
  { value: 'Ethiopia', text: '埃塞俄比亚' },
  { value: 'Fiji', text: '斐济' },
  { value: 'Finland', text: '芬兰' },
  { value: 'France', text: '法国' },
  { value: 'French Guiana', text: '法属圭亚那' },
  { value: 'French Polynesia', text: '法属玻利尼西亚' },
  { value: 'Gabon', text: '加蓬' },
  { value: 'Gambia', text: '冈比亚' },
  { value: 'Georgia', text: '格鲁吉亚' },
  { value: 'Germany', text: '德国' },
  { value: 'Ghana', text: '加纳' },
  { value: 'Gibraltar', text: '直布罗陀' },
  { value: 'Greece', text: '希腊' },
  { value: 'Grenada', text: '格林纳达' },
  { value: 'Guam', text: '关岛' },
  { value: 'Guatemala', text: '危地马拉' },
  { value: 'Guinea', text: '几内亚' },
  { value: 'Guyana', text: '圭亚那' },
  { value: 'Haiti', text: '海地' },
  { value: 'Honduras', text: '洪都拉斯' },
  { value: 'Hungary', text: '匈牙利' },
  { value: 'Iceland', text: '冰岛' },
  { value: 'India', text: '印度' },
  { value: 'Indonesia', text: '印度尼西亚' },
  { value: 'Iran', text: '伊朗' },
  { value: 'Iraq', text: '伊拉克' },
  { value: 'Ireland', text: '爱尔兰' },
  { value: 'Israel', text: '以色列' },
  { value: 'Italy', text: '意大利' },
  { value: 'Ivory Coast', text: '科特迪瓦' },
  { value: 'Jamaica', text: '牙买加' },
  { value: 'Japan', text: '日本' },
  { value: 'Jordan', text: '约旦' },
  { value: 'Kampuchea (Cambodia )', text: '柬埔寨' },
  { value: 'Kazakstan', text: '哈萨克斯坦' },
  { value: 'Kenya', text: '肯尼亚' },
  { value: 'Korea', text: '韩国' },
  { value: 'Kuwait', text: '科威特' },
  { value: 'Kyrgyzstan', text: '吉尔吉斯坦' },
  { value: 'Laos', text: '老挝' },
  { value: 'Latvia', text: '拉脱维亚' },
  { value: 'Lebanon', text: '黎巴嫩' },
  { value: 'Lesotho', text: '莱索托' },
  { value: 'Liberia', text: '利比里亚' },
  { value: 'Libya', text: '利比亚' },
  { value: 'Liechtenstein', text: '列支敦士登' },
  { value: 'Lithuania', text: '立陶宛' },
  { value: 'Luxembourg', text: '卢森堡' },
  { value: 'Madagascar', text: '马达加斯加' },
  { value: 'Malawi', text: '马拉维' },
  { value: 'Malaysia', text: '马来西亚' },
  { value: 'Maldives', text: '马尔代夫' },
  { value: 'Mali', text: '马里' },
  { value: 'Malta', text: '马耳他' },
  { value: 'Mariana Is', text: '马里亚那群岛' },
  { value: 'Martinique', text: '马提尼克' },
  { value: 'Mauritius', text: '毛里求斯' },
  { value: 'Mexico', text: '墨西哥' },
  { value: 'Moldova', text: '摩尔多瓦' },
  { value: 'Monaco', text: '摩纳哥' },
  { value: 'Mongolia', text: '蒙古' },
  { value: 'Montserrat Is', text: '蒙特塞拉特岛' },
  { value: 'Morocco', text: '摩洛哥' },
  { value: 'Mozambique', text: '莫桑比克' },
  { value: 'Namibia', text: '纳米比亚' },
  { value: 'Nauru', text: '瑙鲁' },
  { value: 'Nepal', text: '尼泊尔' },
  { value: 'Netheriands Antilles', text: '荷属安的列斯' },
  { value: 'Netherlands', text: '荷兰' },
  { value: 'New Zealand', text: '新西兰' },
  { value: 'Nicaragua', text: '尼加拉瓜' },
  { value: 'Niger', text: '尼日尔' },
  { value: 'Nigeria', text: '尼日利亚' },
  { value: 'North Korea', text: '朝鲜' },
  { value: 'Norway', text: '挪威' },
  { value: 'Oman', text: '阿曼' },
  { value: 'Pakistan', text: '巴基斯坦' },
  { value: 'Panama', text: '巴拿马' },
  { value: 'Papua New Cuinea', text: '巴布亚新几内亚' },
  { value: 'Paraguay', text: '巴拉圭' },
  { value: 'Peru', text: '秘鲁' },
  { value: 'Philippines', text: '菲律宾' },
  { value: 'Poland', text: '波兰' },
  { value: 'Portugal', text: '葡萄牙' },
  { value: 'Puerto Rico', text: '波多黎各' },
  { value: 'Qatar', text: '卡塔尔' },
  { value: 'Reunion', text: '留尼旺' },
  { value: 'Romania', text: '罗马尼亚' },
  { value: 'Russia', text: '俄罗斯' },
  { value: 'Saint Lueia', text: '圣卢西亚' },
  { value: 'Saint Vincent', text: '圣文森特岛' },
  { value: 'Samoa Eastern', text: '东萨摩亚(美)' },
  { value: 'Samoa Western', text: '西萨摩亚' },
  { value: 'San Marino', text: '圣马力诺' },
  { value: 'Sao Tome and Principe', text: '圣多美和普林西比' },
  { value: 'Saudi Arabia', text: '沙特阿拉伯' },
  { value: 'Senegal', text: '塞内加尔' },
  { value: 'Seychelles', text: '塞舌尔' },
  { value: 'Sierra Leone', text: '塞拉利昂' },
  { value: 'Singapore', text: '新加坡' },
  { value: 'Slovakia', text: '斯洛伐克' },
  { value: 'Slovenia', text: '斯洛文尼亚' },
  { value: 'Solomon Is', text: '所罗门群岛' },
  { value: 'Somali', text: '索马里' },
  { value: 'South Africa', text: '南非' },
  { value: 'Spain', text: '西班牙' },
  { value: 'SriLanka', text: '斯里兰卡' },
  { value: 'St.Lucia', text: '圣卢西亚' },
  { value: 'St.Vincent', text: '圣文森特' },
  { value: 'Sudan', text: '苏丹' },
  { value: 'Suriname', text: '苏里南' },
  { value: 'Swaziland', text: '斯威士兰' },
  { value: 'Sweden', text: '瑞典' },
  { value: 'Switzerland', text: '瑞士' },
  { value: 'Syria', text: '叙利亚' },
  { value: 'Tajikstan', text: '塔吉克斯坦' },
  { value: 'Tanzania', text: '坦桑尼亚' },
  { value: 'Thailand', text: '泰国' },
  { value: 'Togo', text: '多哥' },
  { value: 'Tonga', text: '汤加' },
  { value: 'Trinidad and Tobago', text: '特立尼达和多巴哥' },
  { value: 'Tunisia', text: '突尼斯' },
  { value: 'Turkey', text: '土耳其' },
  { value: 'Turkmenistan', text: '土库曼斯坦' },
  { value: 'Uganda', text: '乌干达' },
  { value: 'Ukraine', text: '乌克兰' },
  { value: 'United Arab Emirates', text: '阿拉伯联合酋长国' },
  { value: 'United Kiongdom', text: '英国' },
  { value: 'United States of America', text: '美国' },
  { value: 'Uruguay', text: '乌拉圭' },
  { value: 'Uzbekistan', text: '乌兹别克斯坦' },
  { value: 'Venezuela', text: '委内瑞拉' },
  { value: 'Vietnam', text: '越南' },
  { value: 'Yemen', text: '也门' },
  { value: 'Yugoslavia', text: '南斯拉夫' },
  { value: 'Zimbabwe', text: '津巴布韦' },
  { value: 'Zaire', text: '扎伊尔' },
  { value: 'Zambia', text: '赞比亚' },
]

export const compareOptions = [
  { label: '<=', value: '小于等于' },
  { label: '>=', value: '大于等于' },
]
