// eslint-disable-next-line object-curly-newline
import { mdiAccount } from '@mdi/js'

export default [
  {
    title: '个人设置',
    icon: mdiAccount,
    children: [
      {
        icon: mdiAccount,
        title: '个人中心',
        to: 'page-account-settings',
      },
    ],
  },
]
