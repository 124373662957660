// eslint-disable-next-line object-curly-newline
import {
  mdiFilePlus,
  mdiVideoPlus,
  mdiFolderTableOutline,
  mdiMonitorDashboard,
} from '@mdi/js'

export default [
  {
    title: '项目运营',
    icon: mdiFolderTableOutline,
    children: [
      {
        icon: mdiMonitorDashboard,
        title: '我的频道',
        to: 'project-channel-list',
      },
      {
        icon: mdiVideoPlus,
        title: '上传视频',
        to: 'video-upload',
      },
      {
        icon: mdiFilePlus,
        title: '上传文件',
        to: 'file-upload',
      },
    ],
  },
]
