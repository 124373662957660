import { ref } from '@vue/composition-api'
import { requestGet } from '@core/utils/form'

export const localUserData = JSON.parse(localStorage.getItem('userData'))
export const localUserList = ref(JSON.parse(localStorage.getItem('userList')))
export const userListFiltered = localUserList.value?.filter(item => item.id !== localUserData.id)
export const curUserData = localUserList.value?.find(item => item.id === localUserData.id)
export const userInfo = JSON.parse(localStorage.getItem('userInfo'))

export function getParsedUserData(userData) {
  if (!userData || !userData.cpType) return {}
  const cpTypeMap = {
    2: {
      isCompany: false,
      auth: userData.isPersonalAuth === 1,
      notYetAuth: userData.isPersonalAuth === 3,
      authStatus: userData.isPersonalAuth,
      name: userData.realName,
    },
    1: {
      isCompany: true,
      auth: userData.isCompanyAuth === 1,
      notYetAuth: userData.isCompanyAuth === 3,
      authStatus: userData.isCompanyAuth,
      contractPersonAuth: userData.isContractPersonalAuth === 1,
      contractPersonNotYetAuth: userData.isContractPersonalAuth === 3,
      contractAuthStatus: userData.isContractPersonalAuth,
      name: userData.enterpriseName,
    },
  }

  return cpTypeMap[userData.cpType]
}

export const refreshUserInfo = () => {
  requestGet('/client/userInfo').then(res => {
    localStorage.setItem('userInfo', JSON.stringify(res.data))
    setTimeout(() => {
      window.location.replace(`${window.location.origin}/pages/account-settings`)
    }, 500)
  })
}

export const refreshUserInfoWithoutRefreshPage = () => {
  requestGet('/client/userInfo').then(res => {
    localStorage.setItem('userInfo', JSON.stringify(res.data))
  })
}
