import Vue from 'vue'
import '@/@fake-db/db'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import XwSearch from '@core/components/search-form/SearchForm.vue'
import XwTable from '@core/components/xw-table/XwTable.vue'
import XwStatus from '@core/components/xw-status/XwStatus.vue'
import NewPagination from '@core/components/Pagination/index.vue'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VXETable)
Vue.component('XwSearch', XwSearch)
Vue.component('XwTable', XwTable)
Vue.component('XwStatus', XwStatus)
Vue.component('NewPagination', NewPagination)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
