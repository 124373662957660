const project = [

  //
  //* ——— Invoice ——————————————————
  //

  {
    path: '/project/channel/list',
    name: 'project-channel-list',
    component: () => import('@/views/project/channel/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/channel/details',
    name: 'project-channel-details',
    component: () => import('@/views/project/channel/Details.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/video-uploads/list',
    name: 'video-uploads',
    component: () => import('@/views/project/video-uploads/List.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/project/video-upload/list',
    name: 'video-upload',
    component: () => import('@/views/project/video-upload/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/file-upload/list',
    name: 'file-upload',
    component: () => import('@/views/project/file-upload/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/real-name-auth/RealNameAuth',
    name: 'real-name-auth',
    component: () => import('@/views/project/real-name-auth/RealNameAuth.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/real-name-auth/CompanyAuth',
    name: 'company-auth',
    component: () => import('@/views/project/real-name-auth/CompanyAuth.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/project/channel-auth/my-channel-auth/List',
    name: 'my-channel-auth-list',
    component: () => import('@/views/project/channel-auth/my-channel-auth/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/billSettlement/beSettle/list',
    name: 'billSettlement-beSettle-list',
    component: () => import('@/views/billSettlement/beSettle/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/billSettlement/history/list',
    name: 'billSettlement-history-list',
    component: () => import('@/views/billSettlement/history/List.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/billSettlement/summary/list',
    name: 'billSettlement-summary-list',
    component: () => import('@/views/billSettlement/summary/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/billSettlement/report',
    name: 'billSettlement-report',
    component: () => import('@/views/billSettlement/Report.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Chat ——————————————————
  //

  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Email ——————————————————
  //

  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default project
