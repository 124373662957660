<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="250"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="pb-3 pt-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          bottom
          :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="localUserData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ curUserData.cpType === 2 ? icons.mdiAccountOutline : icons.mdiAccountGroupOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <div class="text--primary font-weight-semibold mb-n1">
            {{ getParsedUserData(curUserData).name }}
          </div>
          <small class="text-capitalize">
            <v-icon
              small
              :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
            >
              {{ getParsedUserData(userInfo).auth ? 'mdi-checked-circle-outline' : 'mdi-progress-question' }}
            </v-icon>
            {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(userInfo).authStatus) }}
          </small>
        </div>
        <v-icon
          v-if="userListFiltered.length > 0"
          style="margin-left: 8px"
        >
          mdi-arrow-down-drop-circle
        </v-icon>
      </div>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="localUserData.avatar"
              src="@/assets/images/avatars/1.png"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              {{ curUserData.cpType === 2 ? icons.mdiAccountOutline : icons.mdiAccountGroupOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center mx-3"
          style="vertical-align:middle"
        >
          <div class="text--primary font-weight-semibold mb-n1">
            {{ getParsedUserData(curUserData).name }}
          </div>
          <small class="text-capitalize">
            <v-icon
              small
              :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
            >
              {{ getParsedUserData(userInfo).auth ? 'mdi-checked-circle-outline' : 'mdi-progress-question' }}
            </v-icon>
            {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(userInfo).authStatus) }}
          </small>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item
        v-for="item in userListFiltered"
        :key="`${item.id}-`"
        class="my-1"
        title="点击切换账号"
        @click="switchUser(item)"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ getParsedUserData(item).isCompany ? icons.mdiAccountGroupOutline : icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <div
              class="d-flex justify-space-between"
            >
              <div>
                {{ item.realName || item.enterpriseName }}
              </div>

              <div>
                <v-icon
                  :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(item).authStatus)"
                >
                  {{ getParsedUserData(item).auth ? 'mdi-check-circle-outline' : 'mdi-progress-question' }}
                </v-icon>
                {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(item).authStatus) }}
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!--      &lt;!&ndash; Profile &ndash;&gt;-->
      <!--      <v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiAccountOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Profile</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!--      &lt;!&ndash; Email &ndash;&gt;-->
      <!--      <v-list-item :to="{ name: 'apps-email' }">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiEmailOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Inbox</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!--      &lt;!&ndash; Chat &ndash;&gt;-->
      <!--      <v-list-item :to="{ name: 'apps-chat' }">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiChatOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Chat</v-list-item-title>-->
      <!--        </v-list-item-content>-->

      <!--        <v-list-item-action>-->
      <!--          <v-badge-->
      <!--            inline-->
      <!--            color="error"-->
      <!--            content="2"-->
      <!--          >-->
      <!--          </v-badge>-->
      <!--        </v-list-item-action>-->
      <!--      </v-list-item>-->

      <!-- Settings -->
      <v-list-item
        class="mt-2"
        :to="{ name: 'page-account-settings' }"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>设置</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--      &lt;!&ndash; Pricing &ndash;&gt;-->
      <!--      <v-list-item :to="{ name: 'page-pricing' }">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiCurrencyUsd }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Pricing</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!--      &lt;!&ndash; FAQ &ndash;&gt;-->
      <!--      <v-list-item :to="{ name: 'page-faq' }">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiHelpCircleOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>帮助</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>退出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccountGroupOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { toastSuccess } from '@core/utils/prompt'
import { requestGet } from '@core/utils/form'
import { getCurrentInstance } from '@vue/composition-api'
import {
  curUserData,
  localUserData,
  localUserList,
  getParsedUserData,
  userListFiltered,
  userInfo,
  refreshUserInfo,
} from '@core/utils/userData'
import { authStatusOptions } from '@core/utils/options'
import { genResolverFromOptions } from '@core/utils/resolve'
import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('ssoToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userList')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const switchUser = item => {
      requestGet('/client/changeMember', { memberId: item.id }).then(res => {
        const userData = JSON.parse(localStorage.getItem('userData')) || {}
        const { roles } = userData
        localStorage.setItem('userData', JSON.stringify({ ...res.data, roles }))
        toastSuccess('切换成功，即将跳转到个人中心！')
        refreshUserInfo()
      })
    }

    if (!userInfo || userInfo.id !== curUserData.id) {
      console.log(store.state.userData.userInfo, curUserData)
      requestGet('/client/userInfo').then(res => {
        console.log(res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    }

    return {
      userInfo,
      curUserData,
      localUserList,
      localUserData,
      userListFiltered,
      authStatusOptions,
      switchUser,
      logoutUser,
      getParsedUserData,
      genResolverFromOptions,

      icons: {
        mdiAccountOutline,
        mdiAccountGroupOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
