import {
  mdiAccountMultipleCheck,
  mdiCalculatorVariantOutline,
  mdiFolderTableOutline,
  mdiAccount,
} from '@mdi/js'

const AUTH_ENUM = {
  NORMAL: 1,
  SPECIAL: 2,
  SPECIAL_CHILD: 3,
}

export default [
  {
    subheader: '用户控制台',
    authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL, AUTH_ENUM.SPECIAL_CHILD],
  },
  {
    title: '项目运营',
    icon: mdiFolderTableOutline,
    authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL, AUTH_ENUM.SPECIAL_CHILD],
    children: [
      {
        title: '我的频道',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL, AUTH_ENUM.SPECIAL_CHILD],
        to: { name: 'project-channel-list' },
      },
      {
        title: '上传视频',
        authEnum: [AUTH_ENUM.SPECIAL, AUTH_ENUM.SPECIAL_CHILD],
        to: { name: 'video-uploads' },
      },

      {
        title: '上传视频',
        authEnum: [AUTH_ENUM.NORMAL],
        to: { name: 'video-upload' },
      },
      {
        title: '上传文件',
        authEnum: [AUTH_ENUM.NORMAL],
        to: { name: 'file-upload' },
      },
    ],
  },
  {
    title: '财务结算',
    icon: mdiCalculatorVariantOutline,
    authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
    children: [
      {
        title: '待结算',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
        to: { name: 'billSettlement-beSettle-list' },
      },
      {
        title: '历史账单',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
        to: { name: 'billSettlement-history-list' },
      },
      {
        title: '收款汇总',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
        to: { name: 'billSettlement-summary-list' },
      },
    ],
  },
  {
    title: '频道确权',
    icon: mdiAccountMultipleCheck,
    authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
    children: [
      {
        title: '我的频道确权',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
        to: { name: 'my-channel-auth-list' },
      },
    ],
  },

  {
    title: '个人设置',
    icon: mdiAccount,
    authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
    children: [
      {
        title: '个人中心',
        authEnum: [AUTH_ENUM.NORMAL, AUTH_ENUM.SPECIAL],
        to: { name: 'page-account-settings' },
      },
    ],
  },
]
