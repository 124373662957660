<template>
  <div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <btn-group
        :btn-group-list="btnGroupList"
      ></btn-group>
    </div>

    <div
      v-else
      class="d-flex justify-center"
    >
      <icon-menu-list
        :list-items="btnGroupList"
        icon-right=""
      ></icon-menu-list>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from '@vue/composition-api'
import BtnGroup from '@/views/components/common/btn-group/BtnGroup.vue'
import IconMenuList from '@/views/components/common/icon-menu-list/IconMenuList.vue'
import router from '@/router'
import { getParsedUserData, userInfo } from '@core/utils/userData'

export default {
  name: 'AppBarBtn',
  components: {
    BtnGroup,
    IconMenuList,
  },
  setup() {
    const btnGroupList = reactive([
      {
        hidden: computed(() => getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth),
        text: '实名认证',
        eventHandlers: { click: () => router.push({ name: 'page-account-settings' }) },
        class: 'ms-3 px-2',
        color: 'primary',
        componentProps: {
          outlined: true,
        },
      },
      {
        hidden: computed(() => JSON.parse(localStorage.getItem('userInfo')).isConfirm),
        text: '频道确权',
        eventHandlers: { click: () => router.push({ name: 'my-channel-auth-list' }) },
        class: 'ms-3 px-2',
        color: 'primary',
        componentProps: {
          outlined: true,
        },
      },
    ])

    return {
      btnGroupList,
    }
  },
}
</script>

<style scoped>

</style>
