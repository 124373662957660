import { blobParser, getDataWithKeySplit } from '@core/utils/dataProcess'
import { modalFail, toastFail } from '@core/utils/prompt'
import axios from '@axios'
import config from '../../../config'

export const debounce = (fn, interval) => {
  let timeout = null // 创建一个标记用于表示是否存在定时器（有定时器表示在防抖中，通过闭包保存该标记）

  return (...args) => {
    clearTimeout(timeout) // 防抖函数触发时先清除之前的定时器
    timeout = setTimeout(() => { // 清除之前的定时器后重新创建定时器定时，只有在interval时间内不触发防抖函数才会执行该防抖函数
      fn.apply(this, args) // 通过apply传入参数
    }, interval)
  }
}

export function throttle(fn, interval) {
  let canRun = true // 创建一个标记用于表示是否可以运行（不能运行表示在节流中，通过闭包保存该标记）

  return function throttleInner(...args) {
    if (!canRun) return // 在函数开始就判断是否能运行，不能就直接return（还在节流中）
    canRun = false // 若已经超过节流interval则可运行，先设置标记为不能运行
    setTimeout(() => { // 设置定时器并传入interval，即在interval时间内为节流时间
      fn.apply(this, args) // 通过apply传入参数
      canRun = true // 函数执行后则重新设置标记为可运行
    }, interval)
  }
}

export function throttleFirst(func, wait, returnPromise) {
  let context
  let previous = 0

  return function throttleFirstInner(...args) {
    const now = +new Date()
    context = this

    return new Promise((resolve, reject) => {
      if (now - previous > wait) {
        previous = now
        try {
          if (returnPromise) {
            func.apply(context, args).then(res => {
              resolve(res)
            }).catch(err => {
              reject(err)
            })
          } else {
            resolve(func.apply(context, args))
          }
        } catch (e) {
          reject(e)
        }
      } else reject(new Error(`请求过于频繁，两次请求间隔应大于 ${wait} ms！`))
    })
  }
}

export function fetchOptions(suffix, dataSrc, options) {
  const opts = options || {}
  const {
    params, formatter, key, url, targetKey = 'data.data',
  } = opts

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.serviceUrl}${suffix}` || url, {
        params,
      })
      .then(response => {
        if (response.data.status === 200) {
          const data = getDataWithKeySplit(response, targetKey)
          if (formatter) data?.map(formatter)
          // eslint-disable-next-line no-param-reassign
          dataSrc[key] = data
          resolve(response.data)
        } else modalFail(response?.data?.message || '获取详情数据失败！').then()
      })
      .catch(error => {
        modalFail(error?.response?.data?.message || '获取可选项数据失败！').then()
        reject(error)
      })
  })
}

export function fetchDetail(suffix, dataSrc, options) {
  const opts = options || {}
  const {
    params, key, url, targetKey = 'data.data',
  } = opts

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.serviceUrl}${suffix}` || url, {
        params,
      })
      .then(response => {
        console.log(response)
        if (response.data.status === 200) {
          // eslint-disable-next-line no-param-reassign
          dataSrc[key] = getDataWithKeySplit(response, targetKey)
          resolve({ status: response.status, message: response.data.message })
        } else modalFail(response?.data?.message || '获取详情数据失败！').then()
      })
      .catch(error => {
        modalFail(error?.response?.data?.message || '获取详情数据失败！').then()
        reject(error)
      })
  })
}

export function requestGet(suffix, params, options) {
  return new Promise((resolve, reject) => {
    const opts = options || {}
    const {
      url, isBlob = false, headers = {},
    } = opts
    if (isBlob) headers.responseType = 'blob'
    axios.get(`${config.serviceUrl}${suffix}` || url, {
      params,
      ...headers,
    })
      .then(response => {
        if (isBlob) {
          resolve(response)
        } else if (response.data.status === 200) {
          resolve({ data: response.data.data, total: response.data.total, message: response.data.message })
        } else {
          modalFail(response?.data?.message || '获取数据失败！').then()
          reject(response)
        }
      })
      .catch(error => {
        console.log(error)
        if (isBlob) {
          blobParser(error?.response?.data).then(errParsed => {
            modalFail(errParsed.message || '获取数据失败！').then()
            reject(errParsed)
          }).catch(errBlob => reject(errBlob))
        } else {
          modalFail(error?.response?.data?.message || '获取数据失败！').then()
          reject(error)
        }
      })
  })
}

export function requestPost(suffix, data, options) {
  return new Promise((resolve, reject) => {
    const opts = options || {}
    const {
      url, isBlob = false, params = {}, headers = {},
    } = opts
    if (isBlob) headers.responseType = 'blob'
    axios.post(`${config.serviceUrl}${suffix}` || url, data, {
      params,
      ...headers,
    })
      .then(response => {
        console.log(response)
        if (isBlob) {
          resolve(response)
        } else if (response.data.status === 200) {
          resolve({ data: response.data })
        } else {
          modalFail(response?.data?.message || '获取数据失败！').then()
          reject(response)
        }
      })
      .catch(error => {
        console.log(error)
        if (isBlob) {
          blobParser(error?.response?.data).then(errParsed => {
            modalFail(errParsed.message || '获取数据失败！').then()
            reject(errParsed)
          }).catch(errBlob => reject(errBlob))
        } else {
          modalFail(error?.response?.data?.message || '获取数据失败！').then()
          reject(error)
        }
      })
  })
}

export const downloadFile = (fileData, fileName, type = 'excel', preview = false) => {
  if (!fileData) return
  const MIMETypeMap = {
    pdf: 'application/pdf',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  }

  // 创建一个对应MIME类型的blob链接
  const blob = new Blob([fileData], { type: MIMETypeMap[type] })
  const tempUrl = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
  const a = document.createElement('a')
  a.href = tempUrl
  a.target = '_blank'
  if (!preview) a.download = fileName
  a.click()

  // 每次调用URL.createObjectURL,都会创建一个新的URL对象，浏览器内存中会保持对该对象的引用
  // 只有在document销毁时，才会释放此部分内存
  // 在考虑性能的情况下，在url使用结束后，最好释放此部分内存
  window.URL.revokeObjectURL(tempUrl)
}

export const exportFile = (suffix, fileName, queryData, options) => {
  const data = queryData || {}
  const opts = options || {}
  const {
    url, type = 'excel', preview = false, headers = {},
  } = opts
  requestGet(suffix, data, {
    url,
    isBlob: true,
    headers: {
      ...headers,
    },
  })
    .then(response => {
      if (!response) {
        toastFail('导出失败！').then()

        return
      }

      const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')} ${fileName}`
      downloadFile(response.data, fileNameDownload, type, preview)
    })
    .catch(error => {
      console.log(error)
      modalFail(error?.response?.data?.message).then()
    })
}
