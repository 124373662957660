const serviceUrlPro = 'https://xw.xiaowubrother.com/api'

// const serviceUrlDev = 'http://172.16.26.217:8071' // oy

// const serviceUrlDev = 'http://172.16.26.167:8061' // wy

// const serviceUrlDev = 'http://172.16.25.31:8041' // zwx

// const serviceUrlDev = 'http://172.16.26.2:8041' // yhf

// const serviceUrlDev = 'http://172.16.26.52:8061' // dy

// const serviceUrlDev = 'http://172.16.26.65:8041' // DD

const serviceUrlDev = 'http://172.16.24.200:8888' // 测试
const serviceUrlTest = 'http://172.16.24.200:8888' // 测试

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
}
console.log(process.env.NODE_ENV)
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]

// const version = '10'
const version = '13'

const defaultBucket = 'silverdawn-hz'

export default {
  serviceUrl,

  // Endpoints
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,

  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  defaultBucket,
  version,
}
