export const genResolverFromOptions = (options, optionsText = 'text', optionsValue = 'value') => {
  const optionsMap = {}
  options.forEach(item => {
    optionsMap[item[optionsValue]] = item[optionsText]
  })

  return val => optionsMap[val]
}

export const resolveTime = time => {
  if (time) return time.replace('T', ' ')

  return '-'
}
