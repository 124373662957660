<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    status: {
      type: [String, Number],
      default: 0,
    },
    statusMap: {
      type: Object,
      default: () => ({
        SUCCESS: [5, 2],
        FAIL: [6, 3],
        PENDING: [0, 4],
      }),
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const STATUS_CLASS_MAP = {
      FAIL: 'status-fail',
      SUCCESS: 'status-success',
      PENDING: 'status-pending',
    }

    const statusClass = computed(() => {
      const key = Object.keys(props.statusMap).find(x => props.statusMap[x].includes(props.status))

      return STATUS_CLASS_MAP[key]
    })

    return {
      statusClass,
    }
  },
})
</script>

<template>
  <div class="status-item" :class="statusClass">
    {{ text }}
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.status-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-item::before{
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: #00B09B;
  margin-right: 8px;
}
.status-fail::before{
  background: #F24545;
}
.status-success::before{
  background: #00B09B;
}
.status-pending::before{
  background: #FFA80F;
}
</style>
