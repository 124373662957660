<template>
  <div
    v-if="listItemsFiltered ? listItemsFiltered.length : false"
  >
    <v-menu
      :open-on-hover="openOnHover"
      bottom
      left
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            :icon="!btn"
            v-bind="componentProps"
            v-on="eventHandlers"
          >
            <v-icon
              v-if="activatorIcon"
            >
              {{ activatorIcon }}
            </v-icon>
            {{ btn ? label : '' }}
            <v-icon
              v-if="iconRight"
              right
            >
              {{ iconRight }}
            </v-icon>
          </v-btn>
        </div>
      </template>

      <v-list>
        <div
          @click.capture="$emit('bind-actions-item', item)"
        >
          <v-list-item
            v-for="(listItem, index) in listItemsFiltered"
            :key="`${listItem.label}-${index}`"
            :color="listItem.color"
            v-bind="listItem.componentProps"
            v-on="listItem.eventHandlers"
          >
            <v-list-item-title>
              <v-icon
                v-if="listItem.icon"
                size="20"
                class="me-2"
              >
                {{ getDataWithKey(listItem, 'icon') }}
              </v-icon>
              <span
                :class="`${listItem.color}--text`"
              >
                {{ getDataWithKey(listItem, 'text') }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getData, getDataWithKey } from '@core/utils/dataProcess'

export default {
  name: 'IconMenuList',
  props: {
    btn: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '更多',
    },
    activatorIcon: {
      type: String,
      default: 'mdi-dots-vertical',
    },
    iconRight: {
      type: String,
      default: 'mdi-arrow-down-drop-circle-outline',
    },
    openOnHover: {
      type: Boolean,
      default: false,
    },
    listItems: {
      type: Array,
      default: Array,
    },
    item: {
      type: Object,
      default: Object,
    },
    componentProps: {
      type: Object,
      default: Object,
    },
    eventHandlers: {
      type: Object,
      default: Object,
    },
  },
  setup(props) {
    const listItemsFiltered = computed(() => props.listItems.filter(item => !getData(item.hidden, props.item)))

    return {
      listItemsFiltered,
      getDataWithKey,
      getData,
    }
  },
}
</script>

<style scoped>

</style>
