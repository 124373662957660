import operation from './operation'

// import appAndPages from './app-and-pages'
// import chartsAndMaps from './charts-and-maps'
// import dashboard from './dashboard'
// import formsAndTables from './forms-and-tables'
// import others from './others'
// import uiElements from './ui-elements'

// 公司看不到收款汇总页面
// if (JSON.parse(localStorage.getItem('userData'))?.cpType === 2) {
//   operation[2].children.pop()
// }
// 判断是否为子账号，子账号只显示项目运营菜单
const userData = JSON.parse(localStorage.getItem('userData')) || {}
const authType = userData.roles || 1 // 用户枚举
let result = []

result = operation.filter(item => item.authEnum.includes(authType))
result = result.map(item => {
  let res = { ...item }
  if (item.children) {
    res = {
      ...item,
      children: item.children.filter(row => row.authEnum.includes(authType)),
    }
  }

  return res
})

export default [...result]
