// eslint-disable-next-line object-curly-newline
import {
  mdiCalculatorVariantOutline,
  mdiCashMultiple,
  mdiCash,
  mdiCurrencyUsd,
} from '@mdi/js'

export default [
  {
    title: '财务结算',
    icon: mdiCalculatorVariantOutline,
    children: [
      {
        icon: mdiCurrencyUsd,
        title: '待结算',
        to: 'billSettlement-beSettle-list',
      },
      {
        icon: mdiCashMultiple,
        title: '历史账单',
        to: 'billSettlement-history-list',
      },
      {
        icon: mdiCash,
        title: '收款汇总',
        to: 'billSettlement-summary-list',
      },
    ],
  },
]
