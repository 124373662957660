import Vue from 'vue'
import VueRouter from 'vue-router'
import project from './project'
import base from './base'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      // console.log(userData)
      // const userRole = userData && userData.role ? userData.role : null

      if (!userData) return { name: 'auth-login', query: to.query }

      // if (userRole === 'admin') return { name: 'dashboard-crm' }
      // if (userRole === 'client') return { name: 'page-access-control' }

      return { name: 'project-channel-list', query: to.query }
    },
  },

  // ...dashboard,
  // ...userInterface,
  // ...apps,
  // ...pages,
  ...base,
  ...project,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('ssoToken') && localStorage.getItem('userAbility')

  // if (isLoggedIn) {
  //   next({ ...to, replace: true })
  // }

  if (!isLoggedIn && to.name !== 'auth-login') return next({ name: 'auth-login' })

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })
  //
  //   // If logged in => not authorized
  //   // return next({ name: 'misc-not-authorized' })
  //
  //   // return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
