// eslint-disable-next-line object-curly-newline
import {
  mdiAccountMultipleCheck,
  mdiDog,
} from '@mdi/js'

export default [
  {
    title: '频道确权',
    icon: mdiAccountMultipleCheck,
    children: [
      {
        icon: mdiDog,
        title: '我的频道确权',
        to: 'my-channel-auth-list',
      },
    ],
  },
]
